<template>
    <div class="h-[44px] z-50 w-[375px]">
        <div class="flex justify-between items-center px-[16px] py-[7px] h-[30px] w-[375px]">
            <div class="text-ms w-[30px] h-[30px] py-[7px]">
                <router-link to="#" @click="$router.back()">
                    <svg class="w-[30px] h-[30px]"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path fill="#283643" d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z"/>
                    </svg>
                </router-link>
            </div>
            <div class="title color-y ">{{ title }}</div>
            <div class="text-ms w-[30px] h-[30px] py-[7px]">
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    //接收参数
    props: { 
        title: String 
    },
    setup() {

    },
}
</script>

<style>

</style>