<template>
    <div class="mobileBox">
        <!-- <backPage :title="$route.meta.title"  /> -->
        <topBox :title="$route.query.title" />
        <div class="mobileMain">

            <ul class="w-full">
                <li v-for="item in pageData" :key="item.video_id"  class="flex justify-between gap-x-6 p-2" >
                    <div class="flex min-w-0 gap-x-4" 
                        @click="$router.push({ path: '/mobile/play/video', query: { 
                            video_id: item.video_id,
                            title: item.title,
                            src: item.toll,
                            cover: item.cover,
                            created_at: item.created_at,
                        }})"
                    >
                        <img v-if="item.video_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" :src="'/files/' + item.cover" alt="">
                        <img v-if="!item.video_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" src="/img/clocks.png" alt="">
                    </div>
                    <div class="shrink-0 sm:flex sm:flex-col sm:items-end"
                        @click="$router.push({ path: '/mobile/play/video', query: { 
                            video_id: item.video_id,
                            title: item.title,
                            src: item.toll,
                            cover: item.cover,
                            at: item.created_at,
                        }})"
                    >
                        <p class="text-xs-xs text-right leading-1 text-gray-900">&nbsp;</p>
                        <p class="text-xs-xs leading-1 text-gray-500 text-right"> <span class="text-[#fe782f]">{{item.title}}</span> </p>
                        <p class="text-xs-xs leading-1 text-gray-400 text-right">
                            <svg v-show="2==item.status" class="w-3.5 h-3.5 me-2 text-rose-600 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                            </svg>
                            <time>¥ {{item.amount}}</time>
                        </p>
                        <p class="text-xs-xs leading-1 text-gray-500 text-right"><time>{{ ym(item.created_at) }} {{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</time></p>
                    </div>
                </li>
            </ul>

        </div>
        <mainMenu />  
    </div>
</template>

<script>

import { useI18n } from 'vue-i18n'
import topBox from "@/components/TopBox.vue"
import mainMenu from "@/components/mobile/MainMenu.vue"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

// import axios from 'axios'
// import { layer } from "vue3-layer"

export default {
    components: {
        topBox,
        // backPage,
        mainMenu,
        // QuillEditor, 不用编辑器就不要加载 02
    },
    setup() {
        const formatFunc = format()
        return {
            ...formatFunc, // 导出 format 的所有公共方法
        }
    },
    data() {
        return {
            title: this.$t('page.PublishArticle'),
            lang: '',
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 15,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        this.getData()
        return {}
    },
    methods: {
        // 提交视频及附属信息
        runSubmit() {

        }, // End runSubmit() 提交视频及附属信息
        getData()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&lang=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/m/page/video', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                console.log(res)
                // const data = toRaw(res)
                this.pageData = res.data.items
            })
        },  // End getData()...
    }
}
</script>

<style>

</style>