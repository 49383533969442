

export default function formatDate() {

    const date = (time) => {
        let date = new Date(time * 1000) // 参数需要毫秒数，所以这里将秒数乘于 1000
        let year = date.getFullYear().toString()
        let Y = year.slice(2) + '-'
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        let D = date.getDate() < 10 ? '0'+date.getDate() : date.getDate()
        return Y+M+D
    }

    const ym = (time) => {
        let date = new Date(time * 1000) // 参数需要毫秒数，所以这里将秒数乘于 1000
        let year = date.getFullYear().toString()
        let Y = year
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1)
        let D = date.getDate() < 10 ? '0'+date.getDate() : date.getDate()
        return Y + M + '/' + D + '/'
    }
  
    const time = (time) => {
        let date = new Date(time * 1000) // 参数需要毫秒数，所以这里将秒数乘于 1000
        let h = date.getHours() >10 ? date.getHours() + ':' : '0' + date.getHours() + ':'
        let m = date.getMinutes() >= 10 ? date.getMinutes() + ':' : '0' + date.getMinutes() + ':'
        let s = date.getSeconds() >10 ? date.getSeconds()  : '0' + date.getSeconds() 
        return h+m+s
    }
  
    return { date, time, ym }
}