<template>
    <div class="fixed left-0 top-0 h-[50px] w-full z-50 bg-white">
        <div class="grid grid-cols-6 gap-2 mx-2">
            <div class="h-9">
                <router-link to="#" @click="$router.back()">
                    <svg class="w-3 h-3 mx-auto mt-2.5"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path fill="#283643" d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z"/>
                    </svg>
                </router-link>
            </div>
            <div class="title color-y col-span-4" >{{ title }}</div>
            <div class="h-9">
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    //接收参数
    props: { 
        title: String 
    },
}
</script>

<style>

</style>