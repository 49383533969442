<template>
  <div class="carouselBox">
    <div class="carouselBg">
      <div class="flex justify-between items-center">
          <div class="text-xs-xs carouseTitle">左侧元素左侧</div>
          <div class="text-12">
            <button @click="prev">prev</button>
            <button @click="next">next</button>
          </div>
      </div>
      <div class="carousel">
        <div class="inner" ref="inner" :style="innerStyles">
          <div class="card" v-for="card in cards" :key="card">
            <div class="cardBox" >
              <img :src="card.img" alt="" />
              <div class="cardTxt text-12 break-normal">{{card.txt}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      cards: [{img:'/img/5.fafbf248.png',txt:'#3423'}, {img:"/upload/50f4c8fa16c960c9.jpeg",txt:'siofjaoidf'}, {img:"/upload/055bb1150dae2935.png",txt:'摩托车摩托车摩托车摩托车摩托车摩托车'},  {img:"/upload/d49dab039cccaa29.png",txt:'kksw woksk'}, {img:'/img/5.fafbf248.png',txt:'xxxx'}, {img:"/upload/50f4c8fa16c960c9.jpeg",txt:'siofjaoidf'}],
      innerStyles: {},
      step: '',
      transitioning: false
    }
  },

  mounted () {
    this.setStep()
    this.resetTranslate()
  },

  methods: {
    setStep () {
      const innerWidth = this.$refs.inner.scrollWidth
      const totalCards = this.cards.length
      // let px = (innerWidth / totalCards) * 2
      this.step = `${innerWidth / totalCards}px`
      // this.step = px + 'px'
      // console.log( this.step )
    },
    next () {
      if (this.transitioning) return this.transitioning = true
      this.moveLeft()
      this.afterTransition(() => {
        const card = this.cards.shift()
        this.cards.push(card)
        this.resetTranslate()
        this.transitioning = false
      })
    },

    prev () {
      if (this.transitioning) return
      this.transitioning = true
      this.moveRight()
      this.afterTransition(() => {
        const card = this.cards.pop()
        this.cards.unshift(card)
        this.resetTranslate()
        this.transitioning = false
      })
    },

    moveLeft () {
      this.innerStyles = {
        transform: `translateX(-${this.step})
                    translateX(-${this.step})`
      }
    },

    moveRight () {
      this.innerStyles = {
        transform: `translateX(${this.step})
                    translateX(-${this.step})`
      }
    },

    afterTransition (callback) {
      const listener = () => {
        callback()
        this.$refs.inner.removeEventListener('transitionend', listener)
      }
      this.$refs.inner.addEventListener('transitionend', listener)
    },

    resetTranslate () {
      this.innerStyles = {
        transition: 'none',
        transform: `translateX(-${this.step})`
      }
    }
  }
}
</script>

<style>

</style>
