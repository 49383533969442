export default function tool() {

    const isMobile = () => {
        return navigator.userAgent.match(/(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i)
    }

    const checkMobile = (router) => {
        if(isMobile()){
            // 不是手机端则跳转到PC端
            // 逻辑很简单 默认的首页 是手机端的 用户如果永远在首机端 就没有问题
            // 如果用户从手机端切换到了电脑端 那一定是要跳转到 PC 端的首页上来
            router.replace("/m/home")
        } 
    }

    const checkComputer = (router) => {
        if(!isMobile()){
            // 不是手机端则跳转到PC端
            // 逻辑很简单 默认的首页 是手机端的 用户如果永远在首机端 就没有问题
            // 如果用户从手机端切换到了电脑端 那一定是要跳转到 PC 端的首页上来
            router.replace("/c/home")
        } 
    }


    // 生成客户端 UUID 的方法
    const getUuidRadar = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }

    // 能收到 WS 服务端返回的响应
    // 证明已经绑定成功 可以告诉视图的模型 记录下这个状态
    // 不用再次发起绑定了
    const bindTodo = () => {
        return true
    }

    const pingTodo = () => {
        // console.log(arr)
        // console.log('执行了 pingTodo ')
    }

    const okTodo = () => {
        // console.log(arr)
    }

    /**
     * 
     * @param {*} oWs WebSocket 连接对象
     */
    const loginTodo = (oWs, token) => {
        // console.log(arr)
        console.log('通过 WS 发起登录请求')
        oWs.send(
            JSON.stringify({'event': 'ping', 'account': token})
        )
    }


    const NoZooming = () => {
        // 禁用双指放大
        document.documentElement.addEventListener('touchstart', function (event) {
            if (event.touches.length > 1) {
                event.preventDefault()
            }
        }, {
            passive: false
        })
        
        // 禁用双击放大
        var lastTouchEnd = 0
        document.documentElement.addEventListener('touchend', function (event) {
            var now = Date.now();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault()
            }
            lastTouchEnd = now
        }, {
            passive: false
        })
    }


    return { checkMobile, checkComputer, getUuidRadar, bindTodo, pingTodo, okTodo, loginTodo, NoZooming }
}