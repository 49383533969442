<template>
    <div id="computer">

        <div class="grid grid-cols-3 gap-1 signboard">
            <div>
                <div class="inCenterY">
                    <svg class="w-[45px] h-[45px] accountEntrance" @click="openAccountList" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="#FFFFFF" d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/>
                    </svg>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M257 8C120 8 9 119 9 256s111 248 248 248 248-111 248-248S394 8 257 8zm-49.5 374.8L81.8 257.1l125.7-125.7 35.2 35.4-24.2 24.2-11.1-11.1-77.2 77.2 77.2 77.2 26.6-26.6-53.1-52.9 24.4-24.4 77.2 77.2-75 75.2zm99-2.2l-35.2-35.2 24.1-24.4 11.1 11.1 77.2-77.2-77.2-77.2-26.5 26.5 53.1 52.9-24.4 24.4-77.2-77.2 75-75L432.2 255 306.5 380.6z"/></svg> -->
                </div>
            </div>
            <div>
                <div class="inCenter">
                    <div class="circle">
                        <img class="ml-[6px] mt-[4px] mr-[2px] mb-[4px]" src="@/assets/img/favicon-32x32.png" />
                    </div>
                </div>
            </div>
            <div>
                <div class="inCenterY" style="float:right;">
                    <svg class="w-[45px] h-[45px] gameEntrance" @click="openGameList" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="#FFFFFF" d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z"/>
                    </svg>
                    <!-- <img  class="gameEntrance" @click="openGameList" src="@/assets/img/game.32.32.png" /> -->
                </div>
            </div>
        </div>

        <!-- but 2 -->
        <!-- <div class="btn-box" v-if="!isLogin">
           <div class="btn" @click="$router.push({ path: '/computer/login', query: {}})">{{ $t('but.login') }}</div>
           <div class="btn" @click="$router.push({ path: '/computer/register', query: {}})">{{ $t('but.register') }}</div>
       </div> -->
       <!-- /end but 2 -->

        <!-- <van-pull-refresh v-model="loading" @refresh="onRefresh2">
            <p>刷新次数: {{ count }}</p>
        </van-pull-refresh> -->


        <!-- van-pull-refresh：下拉刷新 -->
        <van-pull-refresh  class="demo-refresher" v-model="isLoading" :head-height="50" @refresh="onRefresh" loosing-text="释放即可刷新" pulling-text="下拉刷新" success-text="刷新成功">

            <!-- 上拉加载 -->
            <!-- :finished="finished" // 是否已加载完成，加载完成后不再触发load事件 -->
            <!-- :offset="10" // 滚动条与底部距离小于offset时触发load事件 -->
            <!-- :immediate-check="flase" // 是否在初始化时立即执行滚动位置检查 -->
            <!-- @load="onLoad 滚动条与底部距离小于offset时触发onLoad() -->
            <van-list class="vanList" v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoad" :offset="3">
                <div :class="1==index%2 ? 'videoBox bg5'  : 'videoBox bg6'" v-for="(item,index) in list" :key="index">
                    <div class="grid grid-cols-3 gap-2">
                        <div class="">
                            <div class="btn" @click="$router.push({ path: '/computer/play/video', query: {}})">
                                <div class="cardBox">
                                    <img :src="domain + '/m3u8/' + ym(item.created_at) + item.cover" :alt="item.title" />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <div class="title">{{item.title}}</div>
                            <div class="leftAndRight"> 
                                <div><span class="amountIcon">¥</span><span class="amount">{{item.amount}}</span></div>
                                <div class="nickname">{{ item.user['username']}} {{  index }} </div>
                            </div>
                            <div class="leftAndRight">
                                <div class="get">{{item.amount}}</div>
                                <div class="date">{{date(item.created_at)}} {{time(item.created_at)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
   

    
       


       
        <Carousel />
        <Carousel />
        <Carousel />
        <Carousel />
        <Carousel />
        <Carousel />

        <!-- <div class="btn" @click="$router.push({ path: '/computer/play/video', query: {}})">测试播放视频</div> -->
       

        <!-- swiper 广告的东西放最后 -->
        <div class="swiper">
            <swiper
                :slidesPerView="1"
                :spaceBetween="30"
                :loop="true"
                :centeredSlides="true"
                :pagination="{ clickable: true }"
                :autoplay="{ delay: 3000, disableOnInteraction: false}"
                :navigation="false"
                :modules="modules"
            >
                <swiper-slide><img src="/upload/4ab790e14459500d.png" alt="" /></swiper-slide>
                <swiper-slide><img src="/upload/779a5fc5897127a6.png" alt="" /></swiper-slide>
                <swiper-slide><img src="/upload/a9a212de5251be43.png" alt="" /></swiper-slide>
            </swiper>
            <div class="pagination"></div>
            <div class="swiper-scrollbar"></div>
        </div><!-- /end swiper -->

        

        <mainMenu />

        <!-- 自定义颜色的遮罩层 :shade="[0.5, '#fadfd9']" -->
        <!-- 点击遮罩层则关闭弹出层 :shadeClose="true" -->
        <!-- 不显示关闭按钮 :closeBtn="0" -->
        <s3-layer class="notConfirmed" v-model="layerAccount" :shade="[0.5, '#fadfd9']" :shadeClose="true" :closeBtn="0" :anim="2" offset='50px' :title="title" area="375px">
            <div v-for="i in 2" :key="i">
                {{ i }} 
            </div>
            <div>帐号：sfasdf</div>
            <div>退出</div>
            <div>语言</div>

            <!-- 当前选中的语言 -->
            <div class="flex py-[5px] float-right" @click="toggleDrop">
                <img class="w-[30px] h-[30px] rounded-full border-1 border-gray-50" :src="'/img/'+`${lang}`+'.jpg'"  />
                <div class="text-xs-xs m-[5px]">{{ $t('lang.'+lang) }}</div>
            </div>
            <!-- 弹出用户帐号快捷菜单 -->
            <div v-show="showDropDown" class="languageSelection">
                <div class="py-1 text-left" role="none">
                    <ul>
                        <li v-for="(value, key) in languages" v-bind:key="key" class="text-xs-xs mx-2 my-1.5">
                            <button  @click="switchLang(key)">{{  value  }}</button>
                        </li>
                    </ul>
                </div>
            </div><!-- /End 弹出用户帐号快捷菜单 -->
        </s3-layer>

        <s3-layer class="notConfirmed" v-model="layerGame" :shade="[0.5, '#fadfd9']" :shadeClose="true" :closeBtn="0" :anim="3" offset='50px' :title="title" area="375px">
            <div v-for="i in 10" :key="i">{{ i }}</div>
            <div>赛车</div>
            <div>番摊</div>
        </s3-layer>

    </div>
</template>

<script>
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
// import { ref } from 'vue'
// 轮播图
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue"
// Import Swiper styles
// 引入swiper样式，对应css 如果使用less或者css只需要把scss改为对应的即可
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
// setup 语法糖只需要这样创建一个变量就可以正常使用分页器和对应功能，如果没有这个数组则无法使用对应功能
import { Autoplay, Navigation, Pagination } from "swiper/modules"

// import marquee from "@/components/SimpleMarqueePc.vue"
import mainMenu from "@/components/computer/MainMenu.vue"
import postPages from "@/assets/js/postPages"
import Carousel from '@/components/CarouselList'
import format from '@/assets/js/formatDate'


export default {
    components: {
        //   marquee,
        Swiper,
        SwiperSlide,
        // marquee,
        mainMenu,
        Carousel,

    },
    setup() {
        const onSlideChange = () => {
            console.log("slide change");
        }
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : false
        const isLogin = localStorage.getItem('isLogin') ? localStorage.getItem('isLogin') : 0
        const formatFunc = format()
        return {
            onSlideChange,
            modules: [Autoplay, Navigation, Pagination],
            token,
            isLogin,
            ...formatFunc,
        }
    },
    data() {
        return {
            domain: process.env.VUE_APP_VIDEO,
            lang: '',
            languages: {
                'vi-vn': 'Tiếng Việt',
                'zh-cn': '中文',
            },
            showDropDown: false,
            slide: 0,
            api: {
                commodity: [],
            },
            layerAccount: false, // 控制左侧菜单的显示与隐藏
            layerGame: false, // 控制右侧菜单的显示与隐藏
            title: '帐号管理',
            // 上拉下拉

            //传递给后端的参数
            incomePayData: {
                pageNumber: 1, //请求第几页
                pageSize: 10, //每页请求的数量
            },
            list: [], //列表数据
            listTwo: [], //用于上拉加载时将数据合并
            total: 0, //总页数
            isLoading: false, // 下拉刷新状态 默认为不刷新
            loading: false, //上拉加载
            finished: false, //加载与不加载的状态
            
            // 滚动条的高度
            scrollTop: 0,
            scrollLock: false,
        }
    },

    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        // 初始化页面的数据
        this.getData()

        // 监听滚动条事件
        window.addEventListener('scroll', this.handleScroll, true)
        // this.handleScroll = _debounce(() => {
        //     let top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
        //     if (top >= 60) {
        //         console.log('滚动条回到顶部了')
        //         this.topShow = true
        //     } else {
        //         this.topShow = false
        //     }
        // }, 100)
        // window.addEventListener('scroll', this.handleScroll)

        
        return {}
    },

    // destroyed 生命周期选项被重命名为 unmounted
    // beforeDestroy 生命周期选项被重命名为 beforeUnmount
    unmounted () { 
        // 离开该页面需要移除这个滚动条事件监听事件
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
       
        // 保存滚动值，这是兼容的写法
        handleScroll () {
            // 获取指定 DOM 元素的滚动条坐标
            this.scrollTop = document.querySelector('.van-pull-refresh').scrollTop
            if (0 == this.scrollTop && false == this.scrollLock) {
                // 滚动条一旦触顶 要发起数据刷新请求 但是要有一个锁机制 不然会不断的发起获取刷新数据的请求
                // 即滚动条要再次大于 300 之后才可以现次解锁
                this.onRefresh()
                this.scrollLock = true
            }
            if (300 < this.scrollTop )  this.scrollLock = false
        },

        // 滚动条回到顶部
        backTop () {
            if (this.scrollTop > 10) {
                document.documentElement.scrollTop = 0
            }
        },

        // 用户点击，触发切换语言请求。
        // 记录下用户选择的语言，路由跳转一下。
        switchLang(lang) {
            localStorage.setItem('lang', lang) // 存储
            return this.$router.go(0) // 刷新本页
        },
        toggleDrop() {
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        openAccountList() {
            // layer.msg('打开左侧菜单')
            this.layerAccount = true
        },
        openGameList() {
            // layer.msg('打开右侧菜单')
            this.layerGame = true
        },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&lang=${this.lang}`
            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/m/page/video', this.incomePayData.pageSize, this.incomePayData.pageNumber, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (0===res.code) {
                    this.list = res.data.items //将接口返回赋值data定义的数组
                    this.total = res.data.total //将接口返回的总数据条数赋值data中定义的total
                    if (this.list.length >= this.total) { //将返回数据长度与总条数进行比较控制加载不加载的状态
                        this.finished = true
                    }
                } else {
                    layer.msg(res.msg)
                }
            })
        }, // End getData()
        // 下拉刷新
        onRefresh() {
            this.incomePayData.pageNumber = 1 // 重置页码 起始为第一页
            this.finished = false // 加载与不加载的状态
            setTimeout(() => {
                this.isLoading = false // 下拉刷新状态
                this.getData() //刷新成功调用列表方法
                layer.msg('刷新成功') //轻提示信息
            }, 700)
        },
        // 上拉加载
        onLoad() {
            layer.msg('上拉加载')
            this.incomePayData.pageNumber++ //加载时页码递增
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/m/page/video', this.incomePayData.pageSize, this.incomePayData.pageNumber, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (0===res.code) {
                    this.listTwo = res.data.items //将接口返回赋值data定义的数组
                    this.total = res.data.total //将接口返回的总数据条数赋值data中定义的total
                    this.listTwo.forEach(item => { //循环返回的数据并将数据添加到list中
                        this.list.push(item)
                    })
                    // 加载状态结束 状态更新为false
                    this.loading = false
                    if (this.list.length >= this.total) { //将返回数据长度与总条数进行比较控制加载不加载的状态
                        this.finished = true
                    }
                } else {
                    layer.msg(res.msg)
                }
            })
        }, // End onLoad(...) 上拉加载

        
        
    }
}
</script>

<!-- <style scoped src="../../public/css/main.min.css"></style> -->
<style lang="scss" scoped>
    .top-box-bg {
        // background-image: url('/public/upload/600x55.png');
        background-repeat: no-repeat; /** 图片不要平铺 */
        background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
    }


    .van-pull-refresh { 
        height: calc(100vh - 50px) !important; 
        overflow: auto !important; 
    } 
    .van-list { 
        min-height: calc(100vh) !important; 
        overflow: hidden;
    }
</style>