import { createApp } from 'vue'
import App from './App.vue'
import './utils/rem'

// 进口路由
import router from './router'
// 引入 VueWechatTitle
import VueWechatTitle from 'vue-wechat-title'
// 国际化
import i18n from './language'

// 引入全局样式
// 这个样式由 public/scss/main.scss 编译生成
import '@/../public/css//main.min.css'
// 加载跑马灯
import Vue3Marquee from 'vue3-marquee'

import { s3Layer } from 'vue3-layer'
import 'vue3-layer/dist/s3Layer.css'


import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'


// 1. 引入你需要的组件
import { PullRefresh,List } from 'vant'
// 2. 引入组件样式
import 'vant/lib/index.css'

const app = createApp(App)
    app.use(VueVideoPlayer)
    // 3. 注册你需要的组件
    app.use(PullRefresh)
    app.use(List)
    // 第三方组件全局注册
    app.component('s3-layer', s3Layer)
    // 注册跑马灯
    app.use(Vue3Marquee)
    // 第三方全局注册
    app.use(i18n)
    app.use(VueWechatTitle)
    app.use(router) // 进口路由后要在应用程序中使用
    app.mount('#app')
