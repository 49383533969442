<template>
    <div class="mobileBox">
        <topBox :title="$route.query.title" />
        <div class="mobileMain">

            <h3 class="title mt-28">{{ this.$route.query.title }}</h3>


            <router-link :to="$route.query.url">
                <svg  class="w-[30px] h-[30px] mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path  fill="#fe782f"  d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39L296 392c0 13.3 10.7 24 24 24s24-10.7 24-24l0-134.1 39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"/>
                </svg>
                <!-- 配合 .mobileBox .title 控制文本水平居中 -->
                <div class="title">{{  this.$route.query.explanation }}</div>
            </router-link>


        </div>
        <mainMenu />  
    </div>
</template>

<script>

import { useI18n } from 'vue-i18n'
import topBox from "@/components/TopBox.vue"
import mainMenu from "@/components/mobile/MainMenu.vue"

// import axios from 'axios'
// import { layer } from "vue3-layer"

export default {
    components: {
        topBox,
        // backPage,
        mainMenu,
        // QuillEditor, 不用编辑器就不要加载 02
    },
    setup() {

    },
    data() {
        return {
            title: this.$t('page.PublishArticle'),
            lang: '',
            api: {
                account: null,
                uid: 0,
                invite: null,
                bank_id: null,
            },
            err: {
                title: false,
                amount: false,
                image: false,
                video: false,
                second: false,
            },
            tip: {
                title: false,
                amount: false,
                image: false,
                video: false,
                second: false,
            },
            msg: {
                errSort_id: '必须选择',
                tipTitle: '请输入中文的商品分类名称',
                errTitle: '',
                tipTitle_vi: '请输入越文的商品分类名称',
                errTitle_vi: '',
                tipShop: '请输入中文的店铺名称',
                errShop: '',
                tipShop_vi: '请输入越文的店铺名称',
                errShop_vi: '',
                tipPrice: '请输入商品的价格',
                errPrice: '',
                tipIcon: '请选择分类的LOGO',
                errIcon: '',
                tipSecond: '第几分钟开始收费',
                errSecond: '',
            },
            editor: '',
            form: {
                title: '',
                editor: '',  // 文章正文 因为用了编辑器插件
                pay: '',     // 文章正文 该部份为付费部份
                amount: 0,  // 收费金额
                cover: '',   // 视频的封面图
                video: '',
                second: 0.5,  // 在哪个单位开始收费 30 秒一个单位
            },
            uploadCover: false, // uploadCover 控制是否显示上传封面的进度条
            uploadCoverText: '封面图正在上传，请稍候...',
            uploadVideo: false,
            uploadVideoText: '视频正在上传，请稍候...',
            butSubmit: true,
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        return {}
    },
    methods: {
        // 提交视频及附属信息
        runSubmit() {

        }, // End runSubmit() 提交视频及附属信息
    }
}
</script>

<style>

</style>