<template>
    <div class="mobileBox">
        <!-- <backPage :title="$route.meta.title"  /> -->
        <topBox :title="$route.query.title" />
        <div class="mobileMain">

            m3u8 {{  this.$route.query.src }}

            <video-player
                :src="domain + '/m3u8/' + $route.query.src"
                :poster="domain + '/m3u8/' + ym($route.query.at) + $route.query.cover"
                controls
                :loop="true"
                :volume="0.6"
                width="355px"
                height="266px"
                class="flex justify-center mt-[44px]"
            />

        </div>
        <mainMenu />  
    </div>
</template>

<script>

import { useI18n } from 'vue-i18n'
import topBox from "@/components/TopBox.vue"
import mainMenu from "@/components/mobile/MainMenu.vue"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

// import axios from 'axios'
// import { layer } from "vue3-layer"

import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

export default {
    components: {
        topBox,
        // backPage,
        mainMenu,
        // QuillEditor, 不用编辑器就不要加载 02
        VideoPlayer,
    },
    setup() {
        const formatFunc = format()
        return {
            ...formatFunc, // 导出 format 的所有公共方法
        }
    },
    data() {
        return {
            title: this.$t('page.PublishArticle'),
            lang: '',
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 15,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            // 
            domain: process.env.VUE_APP_VIDEO,
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        this.getData()
        return {}
    },
    methods: {
        // 提交视频及附属信息
        runSubmit() {

        }, // End runSubmit() 提交视频及附属信息
        getData()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&lang=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/m/page/video', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                console.log(res)
                // const data = toRaw(res)
                this.pageData = res.data.items
            })
        },  // End getData()...
    }
}
</script>

<style>

</style>