<template>
    <div class="computerBox">
        <!-- <topMenu /> -->
        <backPage :title="$route.meta.title" />
        <!-- 内容区 -->
        <div class="px-[16px] w-[375px] pt-[44px]">

                <!-- <div class="flex justify-center">
                    <h1 class="color-y text-m16">{{ $t('but.register') }}</h1>
                </div> -->
                
                <div class="">
                    <div class="my-1">
                        <h1 class="text-m14 font-semibold text-slate-900 text-left mb-[10px]">{{ $t('title.account') }}</h1>
                        <input
                            type="text"
                            class="block w-full px-[35px]  py-[15px] border rounded-full bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1  focus:outline-none text-ms"
                            :placeholder="$t('placeholder.user')"
                            maxlength="22"
                            x-model="cardholder"
                            v-model="form.account"
                        />
                    </div>
                    <div class="my-1">
                        <h1 class="text-m14 font-semibold text-slate-900 text-left mb-[10px]">{{ $t('title.password') }}</h1>
                        <input
                            type="password"
                            class="block w-full px-[35px]  py-[15px] border rounded-full bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1  focus:outline-none text-ms"
                            :placeholder="$t('placeholder.password')"
                         
                            maxlength="19"
                            v-model="form.password"
                        />
                    </div>
                    <div class="my-1">
                        <h1 class="text-m14 font-semibold text-slate-900 text-left mb-[10px]">{{ $t('title.confirmPassword') }}</h1>
                        <input
                            type="password"
                            class="block w-full px-[35px]  py-[15px] border rounded-full bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1  focus:outline-none text-ms"
                            :placeholder="$t('placeholder.confirmPassword')"
                    
                            v-model="form.confirm"
                        />
                    </div>
                    <!-- <div class="my-1">
                        <h1 class="text-m14 font-semibold text-slate-900 text-left mb-[10px]">{{ $t('title.inviteCode') }}</h1>
                        <input
                            type="text"
                            class="block w-full px-[35px]  py-[15px] border rounded-full bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1  focus:outline-none text-ms"
                            :placeholder="$t('placeholder.invitation')"
                      
                            v-model="form.invite"
                        />
                    </div> -->
                </div>

                
       
                <div class="mt-0 py-[25px]">
                    <button
                        class="block w-full px-5  py-[15px] border rounded-full bg-blue-500 text-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1  focus:outline-none text-xs-xs"
                        x-bind:disabled="!isValid"
                        x-on:click="onSubmit()"
                        @click="runSubmit"
                    >
                        {{ $t('but.continue') }}
                    </button>
                </div>

     
                <div class="text-xs-xs flex justify-center">
                    <router-link to="/computer/login">
                        <div class="color-y text-m14">{{ $t('text.loginTip') }}{{ $t('but.login') }}</div>
                    </router-link>
                </div>

        </div>
        <mainMenu />
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
// import topMenu from "@/components/computer/TopMenu.vue"
import backPage from "@/components/computer/BackPage.vue"
import mainMenu from "@/components/computer/MainMenu.vue"
import axios from 'axios'
import { layer } from "vue3-layer"

import Message from '@/components/message'
import router from "@/router/index.js"
export default {
    components: {
        // topMenu,
        backPage,
        mainMenu
    },
    setup() {
        // 显示失败信息
        const showMessage1 = () => {
            Message({
                msg: '34',
                type: 'success'
            })
        }
        // 显示成功信息
        const showMessage2 = () => {
            Message.error('45')
        }
        return {
            showMessage1,
            showMessage2
        }
    },
    data() {
        return {
            lang: '',
            form: {
                account: '',
                password: '',
                confirm: '',
                invite: ''
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        // console.log( 'xxx' + this.$route.meta.title )
        return {}
    },
    methods: {
        runSubmit() {
            axios.post(
                '/api/register', 
                {
                    account: this.form.account,
                    password: this.form.password,
                    confirm: this.form.confirm,
                    invite: this.form.invite,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang
                    }
                }
            )
            .then(function (response) {
                if (0===response.data.code) {
                    localStorage.setItem('token', response.data.data.token)
                    router.push({
                        path: '/computer/user',
                        query: {
                            msg: 'reg account'
                        }
                    })
                } else {
                    layer.msg( response.data.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }
    }

}
</script>

<style lang="scss" scoped>

</style>